import { TypeAnimation } from 'react-type-animation';
import {
	Section
} from './TypingAnimationStyles';

const TypingAnimation = () => {
  return (
    <Section>
      <TypeAnimation
        sequence={[
          'We will be live by...', 3000,
          'the next Bitcoin halving!', 7000,
          'Join us...', 3000,
          'to be updated early!', 7000
        ]}
        wrapper="span"
        speed={{type: 'keyStrokeDelayInMs', value: 200}}
        deletionSpeed={{type: 'keyStrokeDelayInMs', value: 90}}
        repeat={Infinity}
      />
    </Section>
  );
};

export default TypingAnimation;
