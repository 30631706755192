import React, { useState, useEffect } from 'react';
//import axios from 'axios';
import http from "../../httpCommon";
import CountdownBlock from '../../subsections/countdownBlock/CountdownBlock';
import {
  Section,
	Loading
} from './InfoBlockStyles';

const InfoBlock = () => {
  //const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';
  //const API_URL = 'https://blockchain.info/latestblock';
  const [latestBlock, setLatestBlock] = useState(null);

  useEffect(() => {
    http.get("/")
        .then(response => {
          setLatestBlock(response.data);
        })
        .catch(error => {
          console.error('Error fetching latest Bitcoin block:', error);
        });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      /*
      axios.get(`${PROXY_URL}${API_URL}`)
        .then(response => {
          setLatestBlock(response.data);
        })
        .catch(error => {
          console.error('Error fetching latest Bitcoin block:', error);
        });
      */
      http.get("/")
        .then(response => {
          setLatestBlock(response.data);
        })
        .catch(error => {
          console.error('Error fetching latest Bitcoin block:', error);
        });
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div>
      {latestBlock ? (
        <div>
          <CountdownBlock latestBlock={latestBlock.height} />
        </div>
      ) : (
        <Loading>
          Loading latest block data...
        </Loading>
      )}
    </div>
  );
};

export default InfoBlock;
