import React, { useState, useEffect } from 'react';
import {
	ContentRow,
  ContentGroup,
  ContentInput,
  Warning,
  ContentGroupPrivacy,
  Label,
  ButtonContainer,
	ContactFormButton
} from './FormComponentStyles';

const FormComponent = ({ onSubmit, privacyPolicyCallback }) => {
  const [contact, setContact] = useState({name: 'name', 
                                          surname: 'surname', 
                                          email: 'email', 
                                          nameError: 'Name cannot be null!',
                                          surnameError: 'Surname cannot be null!',
                                          emailError: 'Email cannot be null!',
                                          emailExample: 'Example: john.doe@gmail.com',
                                          privacy: 'Privacy Policy.'
                                        });

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');

  const [nameError, setNameError] = useState('');
  const [surnameError, setSurnameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  useEffect(() => {
    if (name !== '') 
      validateName();
  }, [name]);

  useEffect(() => {
    if (surname !== '') 
      validateSurname();
  }, [surname]);

  useEffect(() => {
    if (email !== '') 
      validateEmail();
  }, [email]);

  const validateName = () => {
    let isValid = true;
  
    if (!name) {
      setNameError(contact.nameError);
      isValid = false;
    } else {
      setNameError('');
    }
  
    return isValid;
  };
  
  const validateSurname = () => {
    let isValid = true;
  
    if (!surname) {
      setSurnameError(contact.surnameError);
      isValid = false;
    } else {
      setSurnameError('');
    }
  
      return isValid;
    };
  
  const validateEmail = () => {
    let isValid = true; 
  
    if (!email) {
      setEmailError(contact.emailError);
      isValid = false;
    } else if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.([A-Za-z]{2,})$/.test(email)) {
      setEmailError(contact.emailExample);
      isValid = false;
    } else {
      setEmailError('');
    }
  
    return isValid;
  };

  const validateContent = () => {
    let isValid = true;
  
    isValid = validateName();
    isValid = validateSurname();
    isValid = validateEmail();

    return isValid;
  };

  const handlePrivacyPolicy = (value) => {
    privacyPolicyCallback(value);
  };

  const handlePrivacyChange = (event) => {
    setIsPrivacyPolicyAccepted(event.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    if (validateContent()) {
      let details = {
        name: name,
        surname: surname,
        email: email
      };

      onSubmit(details);
    }
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <ContentRow>
        <ContentGroup>
          <ContentInput type="text" 
                        id="name"
                        placeholder={contact.name} 
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required />
            {nameError && <Warning>{nameError}</Warning>}
        </ContentGroup>
        <ContentGroup>
          <ContentInput type="text" 
                        id="surname"
                        placeholder={contact.surname} 
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        required />
            {surnameError && <Warning>{surnameError}</Warning>}
        </ContentGroup>
        <ContentGroup>
          <ContentInput type="email" 
                        id="email"
                        placeholder={contact.email} 
                        onChange={(e) => setEmail(e.target.value)}
                        required />
            {emailError && <Warning>{emailError}</Warning>}
        </ContentGroup>
      </ContentRow>
      <ContentRow className="button">
        <ContentGroupPrivacy>
          <input
            type="checkbox" 
            id="privacyPolicy" 
            checked={isPrivacyPolicyAccepted}
            onChange={handlePrivacyChange}
          />
          <Label htmlFor="privacyPolicy">
            <a onClick={() => { handlePrivacyPolicy(true); }}>{contact.privacy}</a>
          </Label>
        </ContentGroupPrivacy>
        <ContentGroupPrivacy>
          <ButtonContainer>
            <ContactFormButton type="submit" disabled={!isPrivacyPolicyAccepted}>
              Send
            </ContactFormButton>
          </ButtonContainer>
        </ContentGroupPrivacy>
      </ContentRow>
    </form>
  );
};

export default FormComponent;
