import React, { useState, useEffect } from 'react';
import FormComponent from '../../subsections/formComponent/FormComponent';
import {
  Section,
	Button,
  Text
} from './SubscriptionStyles';

const SERVER_API_URL = process.env.REACT_APP_SERVER_API_URL;

const Subscription = ({ privacyPolicyCallback, toggle }) => {
  const [showButton, setShowButton] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (toggle) {
      setShowForm(!showForm); 
      setShowButton(!showButton); 
    }
  }, [toggle]);

  const handleFormSubmit = (data) => {
    setShowForm(!showForm); 
    setShowText(!showText); 
    
    fetch(SERVER_API_URL+"/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed.');
      }
    })
    .then(data => {
      // Visualize confirmation
      console.log('Form data:', data);
    })
    .catch(error => {
      // Handle any errors
      console.error(error);
    }); 
  };

  const handlePrivacyPolicy = (value) => {
    privacyPolicyCallback(value);
  };

  return (
    <Section>
      {showButton &&
        <Button onClick={() => { setShowForm(!showForm); setShowButton(!showButton); }}>
          Subscribe
        </Button>
      }
      {showForm && 
        <FormComponent onSubmit={handleFormSubmit} privacyPolicyCallback={handlePrivacyPolicy} />
      }
      {showText && 
        <Text>
          <div>
            Thanks for join us!
          </div> 
          <div>
            As soon as there is news we will write to you!
          </div>
        </Text>
      }
    </Section>
  );
};

export default Subscription;
