import React, { useState } from 'react';
import { Container } from '../../globalStyles';
import InfoBlock from '../../sections/infoBlock/InfoBlock';
import TypingAnimation from '../../sections/typingAnimation/TypingAnimation';
import Subscription from '../../sections/subcription/Subscription';
import Privacy from '../../sections/privacy/Privacy';
import {
	Component,
	Image,
	Content,
	ContentRow,
} from './HeroStyles';

const Hero = () => {
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [toggleSubscription, setToggleSubscription] = useState(false);

	const handlePrivacyPolicy = (value) => {
		setPrivacyPolicy(value);
		setToggleSubscription(!value);
	}

	return (
		<Container id="hero">
			<Component id="hero">
				<Image className="shadow" src="./images/hero/hero-shadow-bitcoin.png" />
				{ !privacyPolicy ? 
					<Content>
						<ContentRow>
							<TypingAnimation />
						</ContentRow>
						<ContentRow>
							<InfoBlock />
						</ContentRow>
						<ContentRow>
							<Subscription privacyPolicyCallback={handlePrivacyPolicy} toggle={toggleSubscription} />
						</ContentRow>
					</Content>
				:
					<Content>
						<ContentRow>
							<Privacy privacyPolicyCallback={handlePrivacyPolicy} />
						</ContentRow>
					</Content>
				}
			</Component>
		</Container>
	);
};

export default Hero;
