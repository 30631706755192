import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  border: none;
  background: linear-gradient(150deg, #ccc -10%, #222);
  cursor: pointer;
  color: #eee;

  height: 3rem;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 1.5rem;

  @media screen and (max-width: 960px) {
    height: 2.5rem;
    padding: 0.6rem 1.6rem;
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    height: 2rem;
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: 0 0 2rem 0.75rem #eee;
    transition: box-shadow 0.4s ease-in;
    color: #eee;
  }
`;

export const Text = styled.div`
display: flex; 
flex-direction: column; 
align-items: center;
color: #555;
font-style: italic;

font-size: 1.6em;

@media screen and (max-width: 1200px) {
  font-size: 1.45em;
  transform: translateY(-0.5em);
}

@media screen and (max-width: 960px) {
  font-size: 1.3em;
  transform: translateY(-1em);
}

@media screen and (max-width: 720px) {
  font-size: 1.15em;
  transform: translateY(-1.5em);
}

@media screen and (max-width: 600px) {
  font-size: 1.em;
  transform: translateY(-2em);
}

@media screen and (max-width: 480px) {
  font-size: 0.85em;
  transform: translateY(-2.5em);
}
`;
