import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box; 
		margin: 0;
		padding: 0;
		font-family: 'Montserrat', sans-serif;  
	}
`;

export const Container = styled.div`
	width: 100%;
	min-width: 360px;
	max-width: 1920px;
	margin: 0px auto;
	padding: 0px auto;
	color: #eee;
`;

export const Section = styled.section`
	padding: ${({ padding }) => (padding ? padding : '140px 0')};
	margin: ${({ margin }) => (margin ? margin : '')};
	background: ${({ inverse }) => (inverse ? 'white' : '#071c2f')};
	position: ${({ position }) => (position ? position : '')};
	width: ${({ width }) => (width ? width : 'auto')};
	min-width: ${({ minWidth }) => (minWidth ? minWidth : 'auto')};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
	height: ${({ height }) => (height ? height : 'auto')};
	max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : 'auto')};
	min-height: ${({ minHeight }) => (minHeight ? minHeight : 'auto')};
`;

export const Heading = styled.h2`
	margin: ${({ margin }) => (margin ? margin : '')};
	margin-bottom: ${({ mb }) => (mb ? mb : '')};
	margin-top: ${({ mt }) => (mt ? mt : '')};
	color: ${({ inverse }) => (inverse ? '$403ae3' : '#eee')};
	letter-spacing: 0.25rem;
	line-height: 1;
	text-align: center;
	width: ${({ width }) => (width ? width : '100%')};

	font-size: 5.9em; 

	@media screen and (max-width: 1200px) {
    font-size: 4.8em;
  }

  @media screen and (max-width: 960px) {
    font-size: 3.7em;
  }

	@media screen and (max-width: 720px) {
    font-size: 2.6em;
  }

  @media screen and (max-width: 600px) {
    font-size: 2.025em;
  }

	@media screen and (max-width: 480px) {
    font-size: 1.45em;
  }
`;

export default GlobalStyle;
