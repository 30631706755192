import React from 'react';
import { Container } from '../../globalStyles';
import {
	Content,
  ContentRow,
	ContentColumn,
  ContentButton,
  Button
} from './PrivacyStyles';

const Privacy = ({ privacyPolicyCallback }) => {

  const handlePrivacyPolicy = (value) => {
    privacyPolicyCallback(value);
  };

	return (
		<Container id="privacy">
      <Content>
        <ContentRow>
          <ContentColumn>
            <h1>Privacy Policy</h1>
            <h2>Last updated: 2024/01/01</h2>
            <p>We are committed to protecting the privacy of our visitors while they interact with the content, products, and services on our site. This Privacy Policy applies to the Site and all products and services offered.</p>
            <p>This policy gives you an overview of the types of data we collect from you, how we use your information, and the safeguards we have in place to protect it.</p>
            <h2>Information Collection And Use</h2>
            <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information include: name, surname and email.</p>
            <p>The information we collect from you may be used to: personalize your experience and send periodic emails.</p>
            <h2>Sharing Your Personal Information</h2>
            <p>We do not sell, trade, or rent users' personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.</p>
            <h2>Security</h2>
            <p>The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>
            <h2>Your Rights</h2>
            <p>You have the right to access, correct, or delete your personal information maintained by us. You can also object to the processing of your personal information, ask us to restrict the processing of your personal information, or request the portability of your personal information.</p>
            <p>If we have collected and processed your personal information with your consent, then you can withdraw your consent at any time.</p>
          </ContentColumn>
        </ContentRow>
        <ContentRow>
          <ContentColumn className="button">
            <Button onClick={() => { handlePrivacyPolicy(false); }}>
              Back
            </Button>
          </ContentColumn>
        </ContentRow>
      </Content>
		</Container>
	);
};

export default Privacy;
