import styled from 'styled-components';

export const Content = styled.div`
	width: 100%;
	padding: 0 1rem;
	z-index: 10;
	position: relative;
`;

export const ContentRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding: 1rem;
`;

export const ContentColumn = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
  width: 70vw;

	&.button {
		align-items: end;
		padding: 0rem 2rem;
	}

  > h1 {
    font-size: 2rem;
  }

  > h2 {
    padding: 2rem 0 1rem;
  }

  > p {
    text-align: justify;
  }

  @media screen and (max-width: 600px) {
    width: 50vw;

		&.button {
			align-items: center;
			padding: 0rem;
		}

    > h1 {
      font-size: 1.3rem;
    }
  
    > h2 {
      font-size: 1rem;
    }
  
    > p {
      font-size: 0.85rem;
    }
  }

`;

export const ContentButton = styled.div`
	padding: 2rem 1rem;
`;

export const Button = styled.button`
  border: none;
  background: linear-gradient(150deg, #ccc -10%, #222);
  cursor: pointer;
  color: #eee;

  height: 3rem;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 1.5rem;

  @media screen and (max-width: 960px) {
    height: 2.5rem;
    padding: 0.6rem 1.6rem;
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    height: 2rem;
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: 0 0 2rem 0.75rem #eee;
    transition: box-shadow 0.4s ease-in;
    color: #eee;
  }
`;
