import React, { useState, useEffect } from 'react';
import FlipDigit from './utils/flipDigit/FlipDigit';
import {
	Countdown,
  TimeUnit,
  Colon,
  TimeName,
  SubTimeName,
  Extra
} from './CountdownBlockStyles';

const CountdownBlock = ({ latestBlock }) => {
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const [halvingBlock, setHalvingBlock] = useState(861557);
  const [deltaBlocks, setDeltaBlocks] = useState(0);
  const [targetDate, setTargetDate] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    const blocks = halvingBlock - latestBlock
    setDeltaBlocks(blocks);
    const seconds = blocks * 600;
    let now = new Date();
    now.setSeconds(now.getSeconds() + seconds);
    setTargetDate(now);
  }, [latestBlock]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const renderDigits = (number) => {
    return number.toString().padStart(2, '0').split('').map((digit, index) => (
      <div style={{ padding: '2px' }}>
        <FlipDigit key={index} digit={Number(digit)} />
      </div>
    ));
  };

  const formatDate = (date) => {
    if (!(date instanceof Date)) {
      return "Invalid date";
    }

    const pad = (num) => (num < 10 ? `0${num}` : num);
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // Months are zero-indexed
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
  
    return `${year}-${month}-${day}`;
  };  

  return (
    <div>
      <Countdown>
        {Object.values(timeLeft).map((unit, index) => (
          <div>
            <div key={index}> 
              <TimeUnit>
                {renderDigits(unit)}
                <Colon>
                  { index !== 3 && ":" }
                </Colon>
              </TimeUnit>
              <TimeName>
                {index === 0 && <span>days</span>}
                {index === 1 && <span>hours</span>}
                {index === 2 && <span>minutes</span>}
                {index === 3 && <SubTimeName>seconds</SubTimeName>}
              </TimeName>
            </div>
          </div>
        ))}
      </Countdown>
      <Extra>
        <div>
          Actual block: {deltaBlocks}
        </div>
        <div>
          Estimated date: {formatDate(targetDate)}
        </div>
      </Extra>
    </div>
  );
};

export default CountdownBlock;
