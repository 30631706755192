import React from 'react';
import {
	Card,
  CardFace
} from './FlipDigitStyles';

const FlipDigit = ({ digit }) => {
  return (
    <Card>
      <CardFace>{digit}</CardFace>
    </Card>
  );
};

export default FlipDigit;
