import styled from 'styled-components';

export const Card = styled.div`
  width: 5.5em;
  height: 5.5em;

  @media screen and (max-width: 1200px) {
    width: 5em;
    height: 5em;
  }

  @media screen and (max-width: 960px) {
    width: 4.5em;
    height: 4.5em;
  }

	@media screen and (max-width: 720px) {
    width: 4em;
    height: 4em;
  }

  @media screen and (max-width: 600px) {
    width: 3em;
    height: 3em;
  }

	@media screen and (max-width: 480px) {
    width: 2em;
    height: 2em;
  }
`;

export const CardFace = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(150deg, #ccc -10%, #222);
  color: #eee;

  font-size: 4.45em;
  border-radius: 16px;

  @media screen and (max-width: 1200px) {
    font-size: 4.05em;
    border-radius: 14px;
  }

  @media screen and (max-width: 960px) {
    font-size: 3.65em;
    border-radius: 12px;
  }

	@media screen and (max-width: 720px) {
    font-size: 3.25em;
    border-radius: 10px;
  }

  @media screen and (max-width: 600px) {
    font-size: 2.5em;
    border-radius: 8px;
  }

	@media screen and (max-width: 480px) {
    font-size: 1.75em;
    border-radius: 6px;
  }
`;
