import styled from 'styled-components';

export const Countdown = styled.div`
  display: flex; 
  justify-content: center;
`;

export const TimeUnit = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Colon = styled.span`
  display: flex; 
  flex-direction: row; 
  justify-content: center;
  transform: translateY(5px);

  font-size: 3.6em;

  @media screen and (max-width: 1200px) {
    font-size: 3.2em;
  }

  @media screen and (max-width: 960px) {
    font-size: 2.8em;
  }

	@media screen and (max-width: 720px) {
    font-size: 2.4em;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.8em;
  }

	@media screen and (max-width: 480px) {
    font-size: 1.3em;
  }
`;

export const TimeName = styled.div`
  display: flex; 
  flex-direction: row; 
  justify-content: center;

  transform: translateX(-8px);
  font-size: 1.55em;

  @media screen and (max-width: 1200px) {
    transform: translateX(-7px);
    font-size: 1.4em;
  }

  @media screen and (max-width: 960px) {
    transform: translateX(-6px);
    font-size: 1.25em;
  }

	@media screen and (max-width: 720px) {
    transform: translateX(-5px);
    font-size: 1.1em;
  }

  @media screen and (max-width: 600px) {
    transform: translateX(-4px);
    font-size: 0.95em;
  }

	@media screen and (max-width: 480px) {
    transform: translateX(-3px);
    font-size: 0.8em;
  }
`;

export const SubTimeName = styled.span`
  transform: translateX(8px);

  @media screen and (max-width: 1200px) {
    transform: translateX(7px);
  }

  @media screen and (max-width: 960px) {
    transform: translateX(6px);
  }

	@media screen and (max-width: 720px) {
    transform: translateX(5px);
  }

  @media screen and (max-width: 600px) {
    transform: translateX(4px);
  }

	@media screen and (max-width: 480px) {
    transform: translateX(3px);
  }
`;

export const Extra = styled.div`
  display: flex; 
  flex-direction: column; 
  align-items: center;
  color: #555;
  font-style: italic;
  padding: 1em 0 0;

  font-size: 2.1em;

  @media screen and (max-width: 1200px) {
    font-size: 1.9em;
  }

  @media screen and (max-width: 960px) {
    font-size: 1.7em;
  }

	@media screen and (max-width: 720px) {
    font-size: 1.5em;
  }

  @media screen and (max-width: 600px) {
    font-size: 1.3em;
  }

	@media screen and (max-width: 480px) {
    font-size: 1.1em;
  }
`;
