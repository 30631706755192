import styled from 'styled-components';

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: space-evenly;

	&.button {
		justify-content: right;

		@media screen and (max-width: 720px) {
      flex-direction: column;
      align-items: center;
      padding: 0.5rem 0 0 0;
		}
	}

	&.messageError {
		justify-content: left;

		@media screen and (max-width: 720px) {
			padding-bottom: 1rem;
		}
	}
`;

export const ContentGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  margin-bottom: 1rem;

  @media screen and (max-width: 960px) {
    padding: 0 1.5rem;
  }

  @media screen and (max-width: 720px) {
    padding: 0;
  }
`;

export const ContentGroupPrivacy = styled.div`
  display: flex;
`;

export const ContentInput = styled.input`
	height: 2rem;
	width: 20vw;
	font-size: 1rem;

	&.inputError {
		border: 1px solid #111;
	}

	&.hidden {
		visibility: hidden;

		@media screen and (max-width: 720px) {
			display: none;
		}
	}

  &.inputPrivacy {
    padding: 0 1rem;
  }

	&:focus::placeholder {
		visibility: hidden;
		border: 1px solid #ccc;
	}

	@media screen and (max-width: 720px) {
		width: 50vw;
	}
`;

export const Warning = styled.span`
  color: #111;
  font-size: 0.75rem;

	&.input {
		border: 1px solid #111;
	}
`;

export const Label = styled.div`
	display: flex;
	align-items: center;
  justify-content: center;
  padding: 0 0 0 0.5rem;

  > a {
    text-decoration: none;
    cursor: pointer;
    color: #444;
  }
`;

export const ButtonContainer = styled.div`
  padding: 1rem 4.5rem;

  @media screen and (max-width: 960px) {
    padding: 1rem 3rem;
  }

  @media screen and (max-width: 720px) {
    padding: 1rem 2rem;
  }
`;

export const ContactFormButton = styled.button`
  border: none;
  background: linear-gradient(150deg, #ccc -10%, #222);
  cursor: pointer;
  color: #eee;

  height: 3rem;
  padding: 0.8rem 2rem;
  font-weight: 600;
  font-size: 1.1rem;
  border-radius: 1.5rem;

  @media screen and (max-width: 960px) {
    height: 2.5rem;
    padding: 0.6rem 1.6rem;
    font-size: 1rem;
  }

  @media screen and (max-width: 600px) {
    height: 2rem;
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
  }

  &:hover {
    box-shadow: 0 0 2rem 0.75rem #eee;
    transition: box-shadow 0.4s ease-in;
    color: #eee;

    &:disabled {
      cursor: auto;
      box-shadow: none;
    }
  }
`;
