import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// Sections
import GlobalStyles from './globalStyles';
import Hero from './components/hero/Hero';

const App = () => {

  return (
    <Router>
      <GlobalStyles />
      <Routes>
          <Route path="/investors" exact element={<Hero />} />
          {/* default redirect to home page */}
          <Route path="*" element={<Navigate to="/investors" />} />
      </Routes>
    </Router>
  );
};

export default App;
