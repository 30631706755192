import styled from 'styled-components';
import { Section } from '../../globalStyles';

export const Component = styled(Section)`
	background-image: linear-gradient(315deg, #cc5500, #ffbb66);
	background-size: cover;
	background-attachment: fixed;
	z-index: 10;
	justify-content: center;
	align-items: center;
	position: relative;
	display: flex;
	min-height: 100vh;
`;

export const Image = styled.img`
	z-index: 10;
	width: 100%;
	position: absolute;
	left: 0;
	object-fit: cover;

	&.shadow {
		height: 100%;
		max-height: 100%;
		top: 0;
	}
`;

export const Content = styled.div`
	width: 100%;
	padding: 0 1rem;
	z-index: 10;
	position: relative;
`;

export const ContentRow = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 2rem 1rem 2rem;
`;
